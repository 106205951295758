import React from 'react'

import perfectYourMenuBar from './perfect-your-menu-bar.png'
import Section from '@components/ui/section'

export const PerfectYourMenuBar = () => {
  return (
    <Section
      className="bg-secondary-white"
      title="Perfect your menu bar by completely hiding icons"
      description={`Having too many things makes it hard to stay focus. Reduce the stress of getting overwhelmed, one icon at a
    time.`}
      MediaComponent={<img alt="perfect-menu-bar-example" src={perfectYourMenuBar} />}
    />
  )
}
