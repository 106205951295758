import React from 'react'

import hiddenDemoVideo from '@/assets/video/autohide.mp4'
import Section from '@components/ui/section'

export const AutomaticallyHideIcon = () => {
  return (
    <Section
      title="Automatically hide icons again after five seconds"
      description="Icons in menu bar will be hidden after an amount of time after lauching Hidden app. This time interval could be set in the config file."
      isReverse={true}
      MediaComponent={<video src={hiddenDemoVideo} autoPlay={true} muted={true} loop={true} />}
    />
  )
}
