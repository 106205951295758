import React from 'react'
import { cx, css } from 'linaria'

export interface CircleIconListItem {
  text: string
  Img: React.FC
}

export interface CircleIconListProps {
  className?: string
  circleIconList: CircleIconListItem[]
  circleIconListemItemClassName?: string
}

export const CircleIconList = ({
  className,
  circleIconList,
  circleIconListemItemClassName = '',
}: CircleIconListProps) => {
  return (
    <div className={cx('w-full mx-auto', className)}>
      <div className="-mx-4 flex flex-wrap justify-center">
        {circleIconList.map(({ Img, text }) => (
          <div className={cx('flex flex-col items-center h-full px-4 mb-5 flex-1')} key={text}>
            <span
              className={cx(
                'inline-flex items-center justify-center rounded-full overflow-hidden',
                css`
                  width: 70px;
                  height: 70px;
                  margin-bottom: 0.8125rem;
                `,
                circleIconListemItemClassName
              )}
            >
              <Img />
            </span>
            <span
              className={cx(
                'font-500 text-secondary-black font-normal text-center text-base md:text-lg whitespace-no-wrap'
              )}
            >
              {text}
            </span>
          </div>
        ))}
      </div>
    </div>
  )
}
