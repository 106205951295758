import React from 'react'
import startAutomaticImg from './start-automatic.png'

import Section from '@components/ui/section'

export const StartAutomatically = () => {
  return (
    <Section
      className="bg-secondary-white"
      title="Start automatically when you log in"
      description={`Acts as an assistant once you start to work, Hidden’s auto-start feature helps to conduct things instantly without having to open the app every single time.`}
      MediaComponent={<img src={startAutomaticImg} alt="hidden-auto-start-feature" />}
    />
  )
}
