import React from 'react'
import { cx, css } from 'linaria'
import Img from 'gatsby-image'
import { useStaticQuery, graphql } from 'gatsby'

export const CompatibleWithMenuBar = () => {
  const data = useStaticQuery(graphql`
    query {
      mobileLogo: file(relativePath: { eq: "hidden/light-and-dark-menu.png" }) {
        childImageSharp {
          fixed(width: 303, height: 248) {
            ...GatsbyImageSharpFixed_tracedSVG
          }
        }
      }
      desktopLogo: file(relativePath: { eq: "hidden/light-and-dark-menu.png" }) {
        childImageSharp {
          fixed(width: 507, height: 422) {
            ...GatsbyImageSharpFixed_tracedSVG
          }
        }
      }
    }
  `)
  const sources = [
    data?.mobileLogo?.childImageSharp.fixed,
    {
      ...data?.desktopLogo?.childImageSharp?.fixed,
      media: `(min-width: 768px)`,
    },
  ]
  return (
    <section
      className={cx(
        'bg-secondary-white',
        css`
          margin-top: -80%;
          padding-top: 80%;
        `
      )}
    >
      <div className="section container">
        <div className="flex flex-col items-center px-5 md:px-auto">
          <h3
            className={cx(
              'text-center mb-4 text-section-title',
              css`
                top: 200px;
              `
            )}
          >
            Compatible with light and dark menu bars
          </h3>
          <p className="text-lg md:w-3/5 text-center mb-10">
            Regardless of whether you are a long-time user, or just a beginner – you will love Hidden Bar’s new
            interface. Every aspect, every pixel was designed for simplicity. And now you can even choose your preferred
            appearance from a set of stunning built-in themes.
          </p>
        </div>
        <div
          className={cx(
            'flex w-full justify-center',
            css`
              bottom: 72px;
              @media (min-width: 768px) {
                bottom: 50px;
              }
            `
          )}
        >
          <Img fixed={sources} />
        </div>
      </div>
    </section>
  )
}
