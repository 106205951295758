import React from 'react'
import { graphql } from 'gatsby'
import { cx, css } from 'linaria'

import { HiddenBarApp as AppDetail } from '@components/contants'
import { Base } from '@ui/base'
import { Footer } from '@ui/footer'
import { Header } from '@ui/header'
import { CheckOn } from '@ui/check-on'
import { DownloadNow } from '@ui/download-now'

import Seo from '@components/seo-detail-page'
import { Jumbotron } from '@components/ui/jumbotron'
import { CompatibleWithMenuBar } from '@components/hidden/features/compatible-with-menu-bar'
import { FeaturesMacDesignIsFlawLess } from '@components/hidden/features/mac-design-is-flaw-less'
import { AutomaticallyHideIcon } from '@components/hidden/features/automatically-hide-icon'
import { StartAutomatically } from '@components/hidden/features/start-automatically'
import { KeyboarShortcut } from '@components/hidden/features/keyboard-shortcut'
import { PerfectYourMenuBar } from '@components/hidden/features/perfect-your-menu-bar'
import { CustomerReviews } from '@components/ui/customer-reviews'
import demoVideo from '@/assets/video/demo-hidden-bar.mp4'
import boxPng from '@/images/hidden/box.png'
import DemoOnMac from '@ui/demo-on-mac'

const HiddenDetailsPage = ({ data, path }) => {
  const images = data.allScreenshots.edges.map(({ node }) => node.childImageSharp.fixed.src)

  return (
    <>
      <Seo {...AppDetail} video={demoVideo} images={images} />

      <Base>
        <Header className="top-0 sticky w-full z-50" />
        <Jumbotron {...AppDetail} bgClass="bg-black" />
        <FeaturesMacDesignIsFlawLess />
        <section
          className={cx(
            'z-10 relative pb-15 pt-20',
            css`
              margin-top: -20%;
            `
          )}
        >
          <div className="container px-4">
            <DemoOnMac src={demoVideo} />
          </div>
        </section>
        <CompatibleWithMenuBar />
        <AutomaticallyHideIcon />
        <StartAutomatically />
        <KeyboarShortcut />
        <PerfectYourMenuBar />
        <CheckOn {...AppDetail} />
        <CustomerReviews
          title="Customer Reviews"
          content="Based on 12 recent ratings and reviews from"
          rating="5.0"
          {...AppDetail}
        />
        <DownloadNow {...AppDetail} boxImage={boxPng} />
        <Footer path={path} />
      </Base>
    </>
  )
}

export const query = graphql`
  query {
    allScreenshots: allFile(filter: { absolutePath: { regex: "/hidden/screenshots/" } }) {
      edges {
        node {
          childImageSharp {
            fixed(jpegQuality: 100, fit: CONTAIN, width: 1200, height: 630) {
              src
            }
          }
        }
      }
    }
  }
`

export default HiddenDetailsPage
