import React from 'react'
import { css } from 'linaria'
import Section from '@components/ui/section'
import { ReactComponent as KeyboardSvg } from '@/images/hidden/keyboard-shortcut.svg'

export const KeyboarShortcut = () => {
  return (
    <Section
      className={css`
        background-color: #535559;
      `}
      isReverse
      title="Toggle icons with a keyboard shortcut"
      leftColClassName="text-white"
      description={`Anytime you open the app by rolling your mouse pointer over to Hidden icon or tapping on title, you’re wasting time and putting unnecessary strain on your hand. The app allows you to create custom shortcuts without even lifting your hands off of the home row.`}
      RightColumn={
        <div className="md:w-1/2 lg:w-3/5 px-4 self-end">
          <KeyboardSvg />
          {/* <img className="inline-block" src={keyboardShortcut} alt="Toggle icons with a keyboard shortcut" /> */}
        </div>
      }
    />
  )
}
