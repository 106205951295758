import React from 'react'
import { cx, css } from 'linaria'

import { ReactComponent as SvgDistrictrationFree } from './distraction-free.svg'
import { ReactComponent as SvgMinimalistDesktop } from './minimalist-desktop.svg'
import { ReactComponent as SvgSimpleSetup } from './simple-setup.svg'
import { CircleIconList, CircleIconListItem } from '@ui/circle-icon-list'

const circleIconList: CircleIconListItem[] = [
  { text: 'Simple setup', Img: SvgSimpleSetup },
  { text: 'Distraction-free', Img: SvgDistrictrationFree },
  { text: 'Minimalist Desktop', Img: SvgMinimalistDesktop },
]

export const FeaturesMacDesignIsFlawLess = () => {
  return (
    <section
      className={cx(
        'section relative',
        css`
          background: #eff1f2;
          padding-bottom: 20%;
        `
      )}
    >
      <div
        className={cx(
          'container px-4',
          css`
            max-width: 49.5rem;
          `
        )}
      >
        <h2 className="text-section-title text-center mb-6">The macOS design is flawless.</h2>
        <div className="text-section-content text-center mb-20">{`With Hidden, you don’t have to look at those boring icons on your menu bar anymore. This ultra-light menu bar utility will help you to perfect your menu bar by completely hiding icons. Ever feel that the list of apps keep growing in your Mac and along with the icon on your menu bar. They make our menu bar looks so ugly and the simplicity has gone away.`}</div>
        <CircleIconList
          className={css`
            max-width: 35rem;
          `}
          circleIconList={circleIconList}
          circleIconListemItemClassName={css`
            background: linear-gradient(180deg, #007bec 0%, #004dca 108.92%);
          `}
        />
      </div>
    </section>
  )
}
