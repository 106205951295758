import React from 'react'

import { Card } from './card'

import svgStar from './star.svg'
import { ReactComponent as ArrowRightIcon } from '@components/svg/arrow-right.svg'
import { ReviewType } from '@/types'

const Star = ({ className }: { className: string }) => (
  <div className={className}>
    <img src={svgStar} alt="start-icon" />
  </div>
)

export interface CustomerReviewsProps {
  title?: string
  content?: string
  rating?: string
  reviews?: ReviewType[]
  appStoreUrl?: string
  allReviewUrl?: string
  seeAll?: boolean
}

export const CustomerReviews = ({
  title = 'Customer Reviews',
  content,
  rating = '4.9',
  reviews,
  appStoreUrl,
  allReviewUrl,
  seeAll = true,
}: CustomerReviewsProps) => {
  if (!reviews || !appStoreUrl) {
    return null
  }
  return (
    <section className="bg-background-light section">
      <div className="container max-w-3xl px-4">
        <h2 className="text-center text-secondary-black font-500 text-2xl">{title}</h2>
        <div className="pt-4 text-center text-sm text-secondary-black leading-normal">
          {`${content} `}
          <a className="font-700 btn btn-link btn-primary" href={appStoreUrl} target="blank">
            Mac App Store
          </a>{' '}
          worldwide
        </div>
        <div className="pt-4 pb-8 flex items-baseline justify-center">
          <span className="text-2xl text-secondary-black">{rating}</span>{' '}
          <span className="text-sm text-secondary-grey pl-2">out of 5</span>
          <div className="ml-4">
            {[1, 2, 3, 4, 5].map(index => (
              <Star className="inline-block mr-2" key={index} />
            ))}
          </div>
        </div>

        <div className="grid grid-cols-1 row-gap-8 sm:grid-cols-2 sm:gap-8">
          {reviews.map((review, index) => (
            <Card key={index} review={review} />
          ))}
          <div className="hidden md:block"></div>
        </div>
        {seeAll && (
          <div className="text-blue md:text-right mt-0 md:mt-8 text-center">
            <a className="btn btn-link btn-primary" href={allReviewUrl} target="blank" rel="noopener noreferer">
              See all
              <ArrowRightIcon className="ml-2 inline-block" />
            </a>
          </div>
        )}
      </div>
    </section>
  )
}
