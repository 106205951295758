import React from 'react'
import { cx, css } from 'linaria'

export const Card = ({ review }) => {
  const { createdBy, createdAt, title, content, isMore } = review

  return (
    <div className={cx('flex flex-col max-w-sm')}>
      <div className="pb-2 pl-4">
        <span className="text-lg text-secondary-grey">{createdBy}</span>{' '}
        {createdAt && <span className="text-sm text-secondary-grey">- {createdAt}</span>}
      </div>
      <div
        className={cx(
          'bg-white',
          css`
            border-radius: 8px;
            height: 100%;
            padding: 24px 27px;
          `
        )}
      >
        <div className="pb-2 text-sm text-secondary-black font-600">{title}</div>
        <p className="text-sm text-secondary-black">
          {content} {isMore && <span className="cursor-pointer text-blue">more</span>}
        </p>
      </div>
    </div>
  )
}
